body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, button {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea, select, button {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
}

* {
  box-sizing: border-box;
}

body {
  letter-spacing: .1em;
  color: #444;
  font-family: 'Roboto', sans-serif;
  opacity: 0;
  transition: .4s linear;
}

body.is-loaded {
  opacity: 1;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

.source-code {
  font-family: 'Source Code Pro', monospace;
  opacity: 0;
}

.button {
  height: 50px;
  padding: 0 40px;
  text-decoration: none;
  font-size: 24px;
  letter-spacing: .1em;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: #444;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: .2s ease-out;
}

.button:hover {
  background-color: #5e5e5e;
}

.button.is-disabled {
  background-color: #dddddd;
  cursor: auto;
}

.container {
  width: 1000px;
  margin: 0 auto;
  padding: 0 50px 50px;
}

.header {
  margin: 60px 0 70px;
}

.header-title {
  font-size: 36px;
  line-height: 1.3;
  color: #666;
}

.main {
  width: 100%;
  display: flex;
}

.preview {
  width: 540px;
  height: 540px;
  position: relative;
  background-color: #fafafa;
  padding: 10px;
}

.preview:before, .preview:after {
  content: '';
  position: absolute;
  background-color: #eaeaea;
}

.preview:before {
  width: 500px;
  height: 2px;
  left: 20px;
  top: 50%;
  margin-top: -1px;
}

.preview:after {
  width: 2px;
  height: 500px;
  top: 20px;
  left: 50%;
  margin-left: -1px;
}

.preview-scale {
  width: 250px;
  position: absolute;
  top: 110%;
  top: calc(100% + 10px);
  left: 270px;
  text-align: center;
}

.preview-scale:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #666;
}

.preview-scale-inside {
  padding: 0 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.preview-ball {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  border-radius: 50%;
  background-color: #283593;
  transition: opacity .3s linear;
  animation-fill-mode: forwards !important;
}

.preview-ball.is-visible {
  opacity: 1;
}

.preview #box {
  width: 520px;
  height: 520px;
  position: relative;
  z-index: 1;
}

.preview #box.is-hover {
  cursor: grab;
}

.preview #box.is-move {
  cursor: grabbing;
}

.action {
  position: relative;
  padding-left: 60px;
}

.action-option {
  margin-bottom: 60px;
  font-size: 20px;
}

.action-option th, .action-option td {
  padding: 8px;
}

.action-option th {
  text-align: right;
}

.action-option td {
  padding-left: 10px;
}

.action-option select,
.action-option input {
  height: 38px;
  margin: 0;
  border: none;
  font-size: 18px;
  background-color: #eaeaea;
  box-sizing: border-box;
}

.action-option select:focus,
.action-option input:focus {
  background-color: #dddddd;
}

.action-option input {
  width: 120px;
  margin-right: 8px;
  padding: 6px 10px;
}

.action-option select {
  width: 100%;
  appearance: none;
  padding: 6px 10px;
  cursor: pointer;
}

.action-button {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.action-export {
  position: absolute;
  left: 60px;
  bottom: 0;
  font-size: 20px;
  line-height: 1.2;
}

.action-export span {
  display: inline-block;
  margin-left: 20px;
  padding-bottom: 2px;
  border-bottom: 3px solid #0D47A1;
  color: #0D47A1;
  cursor: pointer;
}

.action-export span:first-of-type {
  margin-left: 20px;
}

.modal {
  position: fixed;
  z-index: 10;
  opacity: 0;
  display: none;
  width: 100%;
  height: 120%;
  height: 120vh;
  top: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.8);
  animation-duration: .15s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  cursor: pointer;
}

.modal-inside {
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  pointer-events: none;
}

.modal-area {
  width: 800px;
  margin: 0 auto;
  padding: 50px 0 calc(50px + 20vh);
  text-align: center;
  pointer-events: auto;
}

.modal-contents {
  display: none;
  position: relative;
  background-color: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: auto;
  transition: .2s ease-out;
}

.modal-contents.is-enter {
  display: block;
}

.modal-clipboard {
  position: absolute;
  top: 10px;
  left: 20px;
}

.modal.is-enter {
  display: block;
  animation-name: anime-enter;
}

.modal.is-leave {
  animation-name: anime-leave;
}

.modal-style {
  width: 100%;
  height: 100%;
  padding: 40px;
  text-align: left;
  font-size: 14px;
  font-family: 'Source Code Pro', monospace;
}

.modal-style-box {
  height: 90vh;
  padding-top: 85px;
}

.modal-style-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #444;
}

.modal-style-copy {
  padding: 20px;
  position: relative;
}

.modal-style-copy span {
  display: block;
  padding: 8px 20px;
  font-size: 20px;
  border: 2px solid #fafafa;
  color: #fafafa;
  cursor: pointer;
}

.modal-style-copy span:before {
  content: 'Copy';
}

.modal-style-copy:after {
  content: 'Done!';
  position: absolute;
  left: 100%;
  top: 50%;
  font-size: 14px;
  opacity: 0;
  color: #fafafa;
  transform: translateY(-50%);
}

.modal-style-copy.is-active:after {
  opacity: 1;
}

.modal-style-close {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  color: #fafafa;
  display: flex;
  align-items: center;
}

.modal-style-close:before {
  content: 'Close';
}

@keyframes anime-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anime-leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
