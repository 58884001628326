body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, button {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ol, ul {
  list-style: none; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

a {
  color: inherit;
  text-decoration: none; }

input, textarea, select, button {
  background: none;
  border: none;
  border-radius: 0;
  outline: none; }

* {
  box-sizing: border-box; }
