@import 'variables';
@import 'reset';

body {
  letter-spacing: .1em;
  color: $color-black;
  font-family: 'Roboto', sans-serif;
  opacity: 0;
  transition: .4s linear;
  &.is-loaded {
    opacity: 1; } }
a,
button {
  -webkit-tap-highlight-color: transparent; }

.source-code {
  font-family: 'Source Code Pro', monospace;
  opacity: 0; }

.button {
  height: 50px;
  padding: 0 40px;
  text-decoration: none;
  font-size: 24px;
  letter-spacing: .1em;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: $color-black;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
  transition: .2s ease-out;
  &:hover {
    background-color: lighten($color-black, 10%); }
  &.is-disabled {
    background-color: lighten($color-black, 60%);
    cursor: auto; } }

.container {
  width: 1000px;
  margin: 0 auto;
  padding: 0 50px 50px; }

.header {
  margin: 60px 0 70px;
  &-title {
    font-size: 36px;
    line-height: 1.3;
    color: $color-gray; } }

.main {
  width: 100%;
  display: flex; }

.preview {
  width: 540px;
  height: 540px;
  position: relative;
  background-color: $color-white;
  padding: 10px;
  &:before,
  &:after, {
    content: '';
    position: absolute;
    background-color: #eaeaea; }
  &:before {
    width: 500px;
    height: 2px;
    left: 20px;
    top: 50%;
    margin-top: -1px; }
  &:after {
    width: 2px;
    height: 500px;
    top: 20px;
    left: 50%;
    margin-left: -1px; }
  &-scale {
    width: 250px;
    position: absolute;
    top: 110%;
    top: calc(100% + 10px);
    left: 270px;
    text-align: center;
    &:before {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      top: 10px;
      left: 0;
      background-color: $color-gray; }
    &-inside {
      padding: 0 20px;
      display: inline-block;
      position: relative;
      z-index: 1;
      background-color: #fff; } }
  &-ball {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    border-radius: 50%;
    background-color: $color-indigo;
    transition: opacity .3s linear;
    animation-fill-mode: forwards!important;
    &.is-visible {
      opacity: 1; } }
  #box {
    width: 520px;
    height: 520px;
    position: relative;
    z-index: 1;
    &.is-hover {
      cursor: grab; }
    &.is-move {
      cursor: grabbing; } } }

// action
.action {
  position: relative;
  padding-left: 60px;
  &-option {
    margin-bottom: 60px;
    font-size: 20px;
    th,td {
      padding: 8px; }
    th {
      text-align: right; }
    td {
      padding-left: 10px; }
    select,
    input {
      height: 38px;
      margin: 0;
      border: none;
      font-size: 18px;
      background-color: $color-input;
      box-sizing: border-box;
      &:focus {
        background-color: darken($color-input, 5%); } }
    input {
      width: 120px;
      margin-right: 8px;
      padding: 6px 10px; }
    select {
      width: 100%;
      appearance: none;
      padding: 6px 10px;
      cursor: pointer; } }
  &-button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  &-export {
    position: absolute;
    left: 60px;
    bottom: 0;
    font-size: 20px;
    line-height: 1.2;
    span {
      display: inline-block;
      margin-left: 20px;
      padding-bottom: 2px;
      border-bottom: 3px solid $color-link;
      color: $color-link;
      cursor: pointer;
      &:first-of-type {
        margin-left: 20px; } } } }

.modal {
  position: fixed;
  z-index: 10;
  opacity: 0;
  display: none;
  width: 100%;
  height: 120%;
  height: 120vh;
  top: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling : touch;
  transform: translate3d(0, 0, 0);
  background-color: rgba(#fff, .8);
  animation-duration: .15s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  cursor: pointer;
  &-inside {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
    pointer-events: none; }
  &-area {
    width: 800px;
    margin: 0 auto;
    padding: 50px 0 calc(50px + 20vh);
    text-align: center;
    pointer-events: auto; }
  &-contents {
    display: none;
    position: relative;
    background-color: #fafafa;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: auto;
    transition: .2s ease-out;
    &.is-enter {
      display: block; } }
  &-clipboard {
    position: absolute;
    top: 10px;
    left: 20px; }
  &.is-enter {
    display: block;
    animation-name: anime-enter; }
  &.is-leave {
    animation-name: anime-leave; }
  &-style {
    width: 100%;
    height: 100%;
    padding: 40px;
    text-align: left;
    font-size: 14px;
    font-family: 'Source Code Pro', monospace;
    &-box {
      height: 90vh;
      padding-top: 85px; }
    &-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #444; }
    &-copy {
      padding: 20px;
      position: relative;
      span {
        display: block;
        padding: 8px 20px;
        font-size: 20px;
        border: 2px solid #fafafa;
        color: #fafafa;
        cursor: pointer;
        &:before {
          content: 'Copy'; } }
      &:after {
        content: 'Done!';
        position: absolute;
        left: 100%;
        top: 50%;
        font-size: 14px;
        opacity: 0;
        color: #fafafa;
        transform: translateY(-50%); }
      &.is-active:after {
        opacity: 1; } }
    &-close {
      padding: 20px;
      font-size: 20px;
      cursor: pointer;
      color: #fafafa;
      display: flex;
      align-items: center;
      &:before {
        content: 'Close'; } } } }

@keyframes anime-enter {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes anime-leave {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
